@import '~@travel-ui/styles/src/utility';

.wrapper {
  padding: $spacing-24 140px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  background: $white;

  @include media(pc) {
    padding-top: $spacing-40;
  }

  @include media(sp) {
    padding-left: $spacing-16;
    padding-right: $spacing-16;
  }
}

.errorImg {
  margin: 0 auto;
  width: 247px;
  padding-top: $spacing-16;
  @include media(pc) {
    width: 400px;
    padding-top: 0;
    margin-bottom: $spacing-16;
  }
}

.errorTitle {
  color: $jet;

  @include media(tl, pc) {
    @include font-pc-header-h5-bold;
    align-self: center;
    margin-bottom: $spacing-40;
  }

  @include media(sp) {
    @include font-sp-header-h5-bold;
    margin-bottom: $spacing-24;
  }
}

.resolutionList {
  @include media(tl, pc) {
    margin-bottom: $spacing-40;
  }

  @include media(sp) {
    margin-bottom: $spacing-24;
  }
}

.listTitle {
  display: block;
  color: $jet;
  @include font-sp-header-h6;
  margin-bottom: $spacing-08;
}

.listItem {
  @include font-pc-body-b2;
  list-style-type: '・';
  list-style-position: inside;

  text-indent: -1rem;
  margin-left: 1rem;

  &:not(:last-child) {
    margin-bottom: $spacing-08;
  }
}

.faqLabel {
  margin-bottom: $spacing-08;

  @include media(tl, pc) {
    @include font-pc-body-b2;
  }

  @include media(sp) {
    @include font-sp-header-h6;
  }
}

.faqList {
  display: flex;
  flex-direction: column;
  gap: $spacing-08;
  width: 100%;
  margin-bottom: $spacing-24;

  @include media(pc) {
    margin-bottom: $spacing-40;
  }
}

.faqLink {
  margin-bottom: $spacing-40;
}

.faqItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-08;

  border: 1px solid $pastel-gray;
  @include border-radius-all($border-radius-4px);
  padding: $spacing-08;
  @include media(pc) {
    padding: $spacing-16;
  }

  &:hover,
  &:active {
    background: $shady-white;

    .faqIcon {
      > circle {
        fill: $white;
      }
    }
  }
}

.faqIcon {
  flex-shrink: 0;
}

.faqText {
  flex: 1 1 auto;
  color: $jet;

  @include media(tl, pc) {
    @include font-pc-body-b2;
  }

  @include media(sp) {
    @include font-sp-captions-c1;
  }
}
