// Removing <body> tag margin/padding css
@import '~@travel-ui/styles/src/base';
@import '~@travel-ui/styles/src/utility';

$header-height: 61px;
$max-content-width--pc: 1128px;
$link-color: #1d54a7;

@mixin pc-layout {
  margin-left: auto;
  margin-right: auto;
  padding-left: $page-side-padding--pc;
  padding-right: $page-side-padding--pc;
  max-width: $max-content-width--pc + ($page-side-padding--pc * 2);
  width: 100%;
}

body,
html,
.appWrapper {
  height: 100%;
}

html {
  background-color: $shady-white;

  @include media(pc) {
    background-color: $white;
  }
}

body {
  font-family: 'Hiragino Kaku Gothic Pro';
  word-break: break-word;
}

a {
  // reset a style
  text-decoration: none;
}

.appWrapper {
  display: flex;
  flex-direction: column;
}

.layout {
  flex-grow: 1;
}

// temporary error
.errorWrapper {
  @include flex-center;
  flex-direction: column;
  background: $white;
  padding: $spacing-32;
  text-align: center;
  height: 100%;
}

.errorMessage {
  @include font-pc-body-b3-bold;
  display: block;
  margin-top: $spacing-24;

  @include media(sp) {
    @include font-sp-body-b3-bold;
  }
}

.errorSubtitle {
  @include font-pc-body-b2;
  display: block;
  margin-top: $spacing-16;
  color: $sonic-silver;

  @include media(sp) {
    @include font-sp-captions-c1;
  }
}

.oldPageRedirection {
  display: flex;
  align-items: center;
  color: $dark-cyan;
  margin-top: $spacing-16;

  & > .oldPageRedirectionIcon {
    margin-left: $spacing-08;
  }

  &:hover {
    color: $deep-blue;
  }
}

// dev purpose only - number is calculated by icon size
.envButton {
  @include resetButtonStyle;
  position: relative;
  top: -25px;
  left: 75px;
  width: 50px;
  color: $jet;
  background: $flavescent;
  font-weight: bold;
  font-size: 10px;

  @include media(tl, pc) {
    top: -70px;
    left: 220px;
    font-size: 12px;
  }
}
