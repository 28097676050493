@import '~@travel-ui/styles/src/utility';

.wrapper {
  position: fixed;
  width: 0;
  height: 50px;
  bottom: $spacing-16;
  overflow: visible;
  right: 0;
}

.linkContainer {
  position: absolute;

  right: -100%;
  height: 100%;
  transition: all 0.5s;

  border: 1px solid $pastel-gray;
  border-radius: $border-radius-24px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  background: $white;

  display: flex;
  padding: 0 $spacing-12;
  color: currentColor;
  align-items: center;
  white-space: nowrap;
  opacity: 0;

  &.existed {
    opacity: 1;
    right: $spacing-16;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .title {
    max-width: 200px;
    overflow: hidden;

    &.shrink {
      max-width: 0;
    }
  }
}
