@import '~@travel-ui/styles/src/utility';
@import '../../style/app.module.scss';

$page-side-padding--pc: $spacing-m;

.wrapper {
  @include media(pc) {
    @include pc-layout;
    padding-bottom: $spacing-64;
  }
}
