@import '~@travel-ui/styles/src/utility';

.link {
  color: $current-link-blue;
  text-decoration: none;
  &:hover {
    color: $current-link-deep-blue;
  }

  &.underline {
    text-decoration: underline;
  }
}
